import React from "react"
import { Seo } from "../../components/seo"
// Img
import logoMexico from "../../images/919_mex_logo_800_retina.webp"
import homeQuick from "../../images/home/home-quick.svg"
import homeQuickShadow from "../../images/home/home-quick-shadow.svg"

const AboutEn = () => {
  return (
    <section className="container about-en english-page-body">
      <div className="row">
        <div className="en-pages">
          <h1>About</h1>

          <section className="mb-30">
            <div className="about-quick max-width-size">
              <div className="about-quick_imageshape">
                <img src={homeQuick} alt="quick global" className="image" />
                <img
                  src={homeQuickShadow}
                  alt="quick global"
                  className="shape"
                />
              </div>
              <div>
                <img src={logoMexico} alt="main-logo" />
                <p>
                  QUICK GLOBAL MEXICO, S.A. DE C.V. is the Mexican subsidiary of
                  Quick Co., Ltd., a company that has been involved in the human
                  resources business in Japan for approximately 40 years.
                </p>
              </div>
            </div>
          </section>
          <section className="pr-30 pl-30">
            <h2>Management Philosophy</h2>
            <p className="about-en_text">
              We aim to be a company that makes everyone involved happy. This
              philosophy is the cornerstone that supports both our past and
              future business developments. We strive to make all those
              connected with us and the Quick Group, including shareholders,
              business partners, job seekers, readers, employees and their
              families, and the local community, happy as we continue to grow.
            </p>
            <h2>Business Philosophy</h2>
            <p className="about-en_text">
              We contribute to society through human resources and information
              business. Among the four major management resources—people, goods,
              money, and information—our company focuses consistently on
              "people" and "information" to provide specialized services. By
              comprehensively utilizing the Quick Group's business assets and
              responding to various customer needs, we contribute to society and
              enhance corporate value by earning trust.
            </p>
            <h2>Services</h2>
            <ul className="about-en_text">
              <li>Recruitment services in Mexico Consulting for companies</li>
              <li>Expanding into Mexico</li>
            </ul>
            <h2>Location</h2>
            <h3>
              QUICK GLOBAL MEXICO, S.A. DE C.V. Aguascalientes Office（Head
              Office）
            </h3>
            <div className="about__columns">
              <div className="about__columns__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.333403487488!2d-102.29335058555849!3d21.921730662070644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429efadbb8daf29%3A0xcea673564e790a2b!2sCentro%20Comercial%20Punto%2045!5e0!3m2!1sen!2sbo!4v1646328779092!5m2!1sen!2sbo"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  loading="lazy"
                  title="Aguascalientes Office"
                ></iframe>
              </div>
              <div className="about__columns__details">
                <div>
                  <p className="title-red">Address</p>
                  <div
                    className="about__columns_col"
                    style={{ alignItems: "flex-start" }}
                  >
                    <p className="about-en_info">
                      C.C. Punto 45, Boulevard A Zacatecas 845, Oficina 510,
                      Desarrollo Especial Bulevar a Zacatecas, C.P.20116
                      Aguascalientes, Ags.
                    </p>
                  </div>
                </div>
                <div>
                  <p className="title-red">Phone</p>
                  <div className="about__columns_col">
                    <p className="about-en_info">+52-449-107-5308</p>
                  </div>
                </div>
                <div>
                  <p className="title-red">E-mail</p>
                  <div className="about__columns_col">
                    <p className="about-en_info">quick@919mexico.com</p>
                  </div>
                </div>
              </div>
            </div>
            <h3>QUICK GLOBAL MEXICO, S.A. DE C.V. Querétaro Office</h3>
            <div className="about__columns">
              <div className="about__columns__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.333403487488!2d-102.29335058555849!3d21.921730662070644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429efadbb8daf29%3A0xcea673564e790a2b!2sCentro%20Comercial%20Punto%2045!5e0!3m2!1sen!2sbo!4v1646328779092!5m2!1sen!2sbo"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  loading="lazy"
                  title="Aguascalientes Office"
                ></iframe>
              </div>
              <div className="about__columns__details">
                <div>
                  <p className="title-red">Address</p>
                  <div
                    className="about__columns_col"
                    style={{ alignItems: "flex-start" }}
                  >
                    <p className="about-en_info">
                      Av. Armando Birlain 2001 Corporativo 1 Piso 15A, Colonia
                      Centro Sur (Complejo Central Park), Santiago de Querétaro,
                      Qro. C.P 76090
                    </p>
                  </div>
                </div>
                <div>
                  <p className="title-red">Phone</p>
                  <div className="about__columns_col">
                    <p className="about-en_info">+52-449-111-8229</p>
                  </div>
                  <div>
                    <p className="title-red">Phone</p>
                    <div className="about__columns_col">
                      <p className="about-en_info">+52-449-107-5308</p>
                    </div>
                  </div>
                  <div>
                    <p className="title-red">E-mail</p>
                    <div className="about__columns_col">
                      <p className="about-en_info">quick@919mexico.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {
        //<About language={"en"}></About>
      }
    </section>
  )
}

export default AboutEn

export const Head = () => {
  return (
    <Seo
      title="About | Mexico Jobs, Careers, and Employment Information【QUICK GLOBAL MEXICO】"
      description="QUICK GLOBAL MEXICO, S.A. DE C.V. is the Mexican subsidiary of Quick Co., Ltd., a company that has been involved in the human resources business in Japan for approximately 40 years."
      url={`https://www.919mexico.com/en/about`}
    />
  )
}
